interface Variant {
  text?: string;
  select?: string[];
  label?: string;
  placeholder?: string;
}

interface Variant {
  text?: string;
  select?: string[];
  label?: string;
  placeholder?: string;
}

type variants = Variant[];

export const questions: variants = [
  {},
  {
    text: `To get started, select your version of After Effects`,
    select: ["23", "24", "25"],
    label: "version"
  },

  {
    text: `Select your device`,
    select: ["LAPTOP", "PC"],
    label: "device",
  },

  {
    text: `Name of your device.`,
    placeholder: `MacBook Pro 14`,
    label: "name of device",
  },
  {
    text: `Name of your CPU`,
    placeholder: `M1 MAX 10/24; i7700K)`,
    label: "CPU",
  },
  {
    text: `Name of your video card (optional)`,
    placeholder: `RTX 3080`,
    label: "videocard",
  },
  {
    text: `How much RAM do you have available?`,
    select: ["8", "16", "24", "32", "64", "128", "256"],
    label: "RAM",
  },
  {
    text: `Write what your time render are `,
    placeholder: `10.52 = 10 min, 52 sec`,
    label: "result",
  },
  // {
  //   text: `Write what your time 3D render are `,
  //   placeholder: `10.52 = 10 min, 52 sec`,
  //   label: "3D",
  // },
];

export const axiosArray = [
  "version",
  "device",
  "name",
  "cpu",
  "videocard",
  "ram",
  "time",
];
