import React, { useState } from "react";
import logo from "../../../assets/img/bench_logo.png";
import cl from "./InfoView.module.scss";
import PublicIcon from "@mui/icons-material/Public";
import TelegramIcon from "@mui/icons-material/Telegram";

import { Typography, Box, Button, List, Chip } from "@mui/material";
import text_about from "./textInfo";
import Header from "../../header/Header";

const InfoView = () => {
  const [local, setLocal] = useState(text_about.en);

  return (
    <Box
      sx={{ maxWidth: "100wh", maxHeight: "89vh", overflow: "auto" }}
      className={cl.info}
    >
     <Header text_about={text_about} setLocal={setLocal}/>
      <Box className={cl.logo_wrapper}>
        <img src={logo} alt="SHOCK BENCH LOGO" className={cl.logo} />
        <Chip
          label="Build App - 2.1"
          sx={{
            backgroundColor: "#F4F5FF !important",
            border: "0.5px solid #E5E6F3",
            marginBottom: '15px'
          }}
        />
      </Box>

      <Box className={cl.first_description}>
        <Typography>{local.desc_1}</Typography>
      </Box>

      <Box className={cl.download_block}>
        <Typography className={cl.title}>DOWNLOAD</Typography>
        <Box className={cl.buttons} mb={1}>
          <a href="https://github.com/yo-romlogunov/AeShockBench/releases">
            <Button variant="contained" sx={{ backgroundColor: "#001aff", fontSize: "12px" }}>
              Download Shock Bench
            </Button>
          </a>
          <a href="https://drive.google.com/drive/folders/1SyMTAnBU7GB3x8IM6ELUUsBAhpHpAEY_">
            <Button
              variant="contained"
              sx={{ color: "white", backgroundColor: "black", fontSize: "12px" }}
            >
              Google Drive
            </Button>
          </a>
        </Box>
      </Box>

      <Box className={cl.person_block}>
        <Typography className={cl.title}>MEMBERS</Typography>
        <Typography mb={1}>DEVELOPER APP</Typography>
        <Box className={cl.person_item}>
          <Box className={cl.name}>
            <Typography>Michael Lipovka</Typography>
          </Box>
          <Box className={cl.icons}>
            <Box className={cl.icon}>
              <a href="https://t.me/miwist">
                <TelegramIcon />
              </a>
            </Box>
            <Box className={cl.icon}>
              <a href="https://t.me/compas_digital">
                <PublicIcon />
              </a>
            </Box>
          </Box>
        </Box>
        <Typography mb={1}>BENCH, DESIGN</Typography>
        <Box className={cl.person_item}>
          <Box className={cl.name}>
            <Typography>Roman Logunov</Typography>
          </Box>
          <Box className={cl.icons}>
            <Box className={cl.icon}>
              <a href="https://t.me/romlogunov_hub">
                <TelegramIcon />
              </a>
            </Box>
            <Box className={cl.icon}>
              <a href="https://github.com/yo-romlogunov">
                <PublicIcon />
              </a>
            </Box>
          </Box>
        </Box>
        <Typography mb={1}>SPECIAL FOR</Typography>
        <Box className={cl.person_item}>
          <Box className={cl.name}>
            <Typography>Rozetked</Typography>
          </Box>
          <Box className={cl.icons}>
            <Box className={cl.icon}>
              <a href="https://t.me/rozetked">
                <TelegramIcon />
              </a>
            </Box>
            <Box className={cl.icon}>
              <a href="https://www.youtube.com/@rozetked">
                <PublicIcon />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

      <Typography className={cl.title} paragraph>
        {local.desc_2}
      </Typography>
      <Typography>{local.desc_3}</Typography>
      <List sx={{ paddingLeft: "20px" }}>
        <ul>
          <li>{local.desc_3_1}</li>
          <li>{local.desc_3_2}</li>
          <li>{local.desc_3_3}</li>
        </ul>
      </List>
      <Typography paragraph>{local.desc_4}</Typography>
      <Typography paragraph>{local.desc_5}</Typography>
      <Typography className={cl.title}> {local.desc_6}</Typography>
      <List sx={{ paddingLeft: "20px", marginBottom: "10px" }}>
        <ol>
          <li>{local.desc_6_1}</li>
          <li>{local.desc_6_2}</li>
          <li>{local.desc_6_3}</li>
          <li>{local.desc_6_4}</li>
        </ol>
      </List>
      <Box className={cl.bottom_buttons}>
        <a href="https://github.com/yo-romlogunov/AeShockBench/blob/main/manual.pdf">
          <Button variant="contained" sx={{ backgroundColor: "#001aff" }}>
            PDF MANUAL
          </Button>
        </a>
        <a href="https://t.me/romlogunov">
          <TelegramIcon />
        </a>
        <Typography>
          I need @help?
          <br />
          Send message.
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoView;
