import React, { useState } from "react";
import { Modal, Box, Typography, Button, Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalCard = ({ open, setOpen, deleteCard, result }: any) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Удаление своего результата
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            Вы действительно хотите удалить результат {result}?
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Button onClick={deleteCard}>Да</Button>
            </Grid>
            <Grid item xs={6}>
              <Button onClick={() => setOpen(false)}>Нет</Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModalCard;
