import axios from "axios";

let apiUrl = process.env.REACT_APP_API;
const apiUrlDevice = process.env.REACT_APP_API_DEVICES;

const ajax_get = (url: string, func: any, device: boolean) => {
  let items: any = [];

  if (device) {
    apiUrl = apiUrlDevice;
  }

  axios
    .get(apiUrl + url)
    .then((res) => {
      if (res) {
        items = res.data;
        func(items);
      }
    })
    .catch((e) => {
      console.log(e);
      return;
    });
};

export default ajax_get;
