import Mobile from "./components/mobile/Mobile";
import { Box, Typography } from "@mui/material";
import { Provider } from "react-redux";
import store from "./components/redux";
import TelegramIcon from "@mui/icons-material/Telegram";

//@ts-ignore
const tg = window.Telegram.WebApp;
let user_id = tg?.initDataUnsafe?.user?.id;

function App() {
  return (
    <Provider store={store}>
      {user_id ? (
        <Mobile />
      ) : (
        <Box className="app">
          <Typography variant="h5">Зайдите через телеграм</Typography>
          <Box className="icon">
            <a href="https://t.me/ShockBenchBot">
              Перейти
              <TelegramIcon />
            </a>
          </Box>
        </Box>
      )}
    </Provider>
  );
}

export default App;
